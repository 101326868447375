<template>
  <div>
    <b-card-code title="Add User" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- first name -->
            <b-col md="6">
              <b-form-group label="First Name" label-for="first-name">
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <b-form-input
                    id="first-name"
                    v-model="userData.firstname"
                    placeholder="John"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- lastname -->
            <b-col md="6">
              <b-form-group label="Last Name" label-for="last-name">
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required"
                >
                  <b-form-input
                    id="last-name"
                    v-model="userData.lastname"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Doe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- username -->
            <b-col md="6">
              <b-form-group
                v-b-popover.hover.top="'Please Enter Username'"
                label="Username"
                label-for="username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="userData.username"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- email -->
            <b-col md="6">
              <b-form-group
                v-b-popover.hover.top="'Please Enter Valid Email'"
                label="Email"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="userData.email"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Example@MobileMasr.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- password -->
            <b-col md="6">
              <b-form-group
                v-b-popover.hover.top="'Choose a password'"
                label="Password"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-form-input
                    autocomplete="password"
                    id="password"
                    v-model="userData.password"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- confirm password -->
            <b-col md="6">
              <b-form-group
                v-b-popover.hover.top="'Confirm Password'"
                label="Confirm Password"
                label-for="c-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password Confirm"
                  rules="required|confirmed:Password"
                >
                  <b-form-input
                    autocomplete="c-password"
                    id="c-password"
                    v-model="userData.password_confirmation"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Re-type Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- gender -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Gender"
                rules="required"
              >
                <b-form-group
                  label="Gender"
                  label-for="gender"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="country"
                    v-model="genderSelect"
                    :state="genderSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="genderOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- birthday -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Birthday"
                rules="required"
              >
                <b-form-group
                  label="Birthday"
                  label-for="Birthday"
                  :state="errors.length > 0 ? false : null"
                >
                  <flat-pickr
                    v-model="userData.birthdate"
                    class="form-control"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- address -->
            <b-col md="6">
              <b-form-group label="Address" label-for="address">
                <validation-provider
                  #default="{ errors }"
                  name="Address"
                  rules="required"
                >
                  <b-form-input
                    id="address"
                    v-model="userData.address"
                    placeholder="15 st, maddi, cairo  egypt"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- phone -->
            <b-col md="6">
              <b-form-group label="Phone" label-for="phone">
                <validation-provider
                  #default="{ errors }"
                  name="Phone"
                  rules="required|integer"
                >
                  <cleave
                    id="phone"
                    v-model="userData.phone"
                    type="number"
                    class="form-control"
                    :options="options.phone"
                    placeholder="xxxxxxxx"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Profile picture -->
            <b-col md="6">
              <b-form-group label="Profile picture" label-for="profile-picture">
                <validation-provider
                  #default="{ errors }"
                  name="Profile picture"
                  rules="required"
                >
                  <b-form-file
                    v-model="ProfilePictureBase64"
                    @change="uploadImage"
                    id="ProfilePictureBase64"
                    accept=".jpg, .png"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col> </b-col>
          </b-row>
          <!-- submit button -->
          <b-button
            size="lg"
            variant="primary"
            type="submit"
            @click.prevent="validationForm"
          >
            Submit
          </b-button>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values , index) in errors_back" :key="index">
          <li v-for="(value , valIndex) in values" :key="valIndex">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import BCardCode from '@core/components/b-card-code'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { heightTransition } from '@core/mixins/ui/transition'
import { required, integer } from '@validations'
import flatPickr from 'vue-flatpickr-component'
export default {
  components: {
    BCardCode,
    flatPickr,
    Cleave,
  },

  
  mixins: [heightTransition],
  data() {
    return {
      genderSelect: null,
      genderOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'male',
          text: 'Male',
        },
        {
          value: 'female',
          text: 'Female',
        },
      ],
      ProfilePictureBase64: [],
      userData: {
        firstname: '',
        lastname: '',
        email: '',
        username: '',
        birthdate: '',
        address: '',
        phone: '',
        profile_picture: '',
        gender: '',
        password: '',
        password_confirmation: '',
      },
      errors_back: [],
      showDismissibleAlert: false,
      required,
      integer,
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'EG',
        },
      },
    }
  },
  methods: {
    // submit area API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.userData.gender = this.genderSelect.value
          if (this.userData.profile_picture.includes('data:image/')) {
            this.userData.profile_picture = this.userData.profile_picture
              .split(',')
              .map((item) => item.trim())
            this.userData.profile_picture = this.userData.profile_picture[1]
          }
          axios
            .post('users/create', this.userData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.push('/User/Index')
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    // cencode profile picture
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.userData.profile_picture = e.target.result
      }
      reader.readAsDataURL(file)
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
​
<style lang="scss">
</style>
